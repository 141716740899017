<template>
  <span>
    <v-chip
      small
      outlined
      color="green"
      v-if="status.id !== 'sent' || status.type === 'email' || status.type === 'text-message' || status.type === 'phone-campaign'"
    >
      <document-name-ref
        :id="status.id"
        :cache-type="cacheTypeDunningStatus"
      />
    </v-chip>
    <div
      v-else-if="status.type === 'ar24'"
      class="d-flex"
    >
      <v-chip
        small
        outlined
        :color="colorAr24"
      >
        <span v-if="status.ar24MessageStatus">
          {{ ar24StatusText }}
        </span>
        <span v-else> {{$t('t.ToSendByAr24')}}</span>
      </v-chip>
    </div>
    <div
      v-else-if="status.type === 'maileva'"
      class="d-flex"
    >
      <v-chip
        small
        outlined
        :color="colorMaileva"
      >
        <span v-if="status.mailevaLetterStatus">
          {{ mailevaStatusText }}
        </span>
        <span v-else-if="status.addressMailevaInvalid"> {{$t('t.AddressInvalid')}}</span>
        <span v-else> {{$t('t.ToSendByMaileva')}}</span>
      </v-chip>
    </div>
    <div
      v-else-if="status.type === 'letter'"
      class="d-flex"
    >
      <v-chip
        small
        outlined
        :color="color"
      >
        <span v-if="status.userId">
          {{$t('t.LetterDownloaded')}} {{formatDateTime(status.date)}}
        </span>
        <span v-else>
          {{$t('t.LetterNotDownloaded')}}
        </span>
      </v-chip>
      <computed-document-ref
        v-if="status.userId"
        class="mx-5 my-n2"
        :id="status.userId"
        :options="{hideNavigation: true}"
        documentType="users"
      >
      </computed-document-ref>
    </div>
  </span>
</template>

<script>

import { CacheType, getDoc } from '@/wasm/pkg'
export default {
  components: {
    ComputedDocumentRef: () => import('@/components/documents-ref/computed-document-ref'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  data () {
    return {
      cacheTypeDunningStatus: this.documentType === 'dunning-reminders' ? CacheType.DunningReminderStatusRef : CacheType.CalendarBasedReminderStatusRef,
      cacheTypeAr24MessageStatus: CacheType.Ar24MessageStatusRef,
      cacheTypeMailevaLetterStatus: CacheType.MailevaLetterStatusRef,
      ar24MessageStatus: null,
      mailevaLetterStatus: null
    }
  },
  computed: {
    ar24StatusText () {
      return this.$t('t.LetterDematerialized') + ' ' + this.ar24MessageStatus?.name?.toLowerCase() + ' ' + this.$t('t.At') + ' ' + this.formatDateTime(this.status.date)
    },
    mailevaStatusText () {
      return this.$t('t.MailevaLetter') + ' ' + this.mailevaLetterStatus?.name?.toLowerCase() + ' ' + this.$t('t.At') + ' ' + this.formatDateTime(this.status.date)
    },
    colorAr24 () {
      return this.status.ar24MessageStatus ? 'success' : 'error'
    },
    colorMaileva () {
      return this.status.mailevaLetterStatus ? 'success' : 'error'
    },
    color () {
      return this.status.userId ? 'success' : 'error'
    },
    isDue () {
      return !this.invoice.paid && this.moment(this.invoice.due) < this.moment().startOf('day')
    },
    days () {
      const today = this.moment().startOf('day')
      return today.diff(this.moment(this.invoice.due)) ? this.moment(this.invoice.due).from(today) : today.calendar(null, { sameDay: `[${this.$t('t.Today')}]` })
    },
    paidText () {
      if (!this.invoice.paid) {
        return ''
      }
      const paid = this.moment(this.invoice.paid)
      const now = this.moment()

      if (now.diff(paid, 'days') >= 15) {
        return paid.calendar(null, { sameDay: `[${this.$t('t.Today')}]` })
      }

      return paid.from(this.moment().startOf('day'))
    }
  },
  watch: {
    'status.ar24MessageStatus': {
      immediate: true,
      async handler (n) {
        if (n) {
          this.ar24MessageStatus = await getDoc(CacheType.Ar24MessageStatusRef, n)
        }
      }
    },
    'status.mailevaLetterStatus': {
      immediate: true,
      async handler (n) {
        if (n) {
          this.mailevaLetterStatus = await getDoc(CacheType.MailevaLetterStatusRef, n)
        }
      }
    }
  },
  props: {
    status: Object,
    documentType: String
  }
}
</script>
